.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 10px;
}

.gallery .pics {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 15px;
}

.pics:hover {
  filter: brightness(0.8);
}

.modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.modalOpen {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.modal img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.modalClose {
  position: fixed;
  top: 40px;
  left: 95%;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1023px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .modalClose{
    left: 90%;
  }
}

@media screen and (max-width: 600px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .modalClose{
    left: 85%;
  }
}
