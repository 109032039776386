

/* Icon V Hover Effect Starts ---------------------------------------*/
.iconVColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconV{
    opacity: 1;
    transition: all 0.5s linear;
}

.iconVContainer:hover .iconVColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconVContainer:hover .iconV{
    opacity: 0;
    transform: translateY(-0.8rem);
}

/* Icon V Hover Effect Ends ---------------------------------------*/


/* Icon I Hover Effect Starts ---------------------------------------*/
.iconIColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}

.iconI1{
    opacity: 1;
    transition: all 0.5s linear;
}

.iconIContainer:hover .iconIColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconIContainer:hover .iconI1{
    opacity: 0;
    transform: translateY(-0.8rem);
}

/* Icon I Hover Effect Ends ---------------------------------------*/

/* Icon B Hover Effect Starts ---------------------------------------*/

.iconBColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconB{
    opacity: 1;
    transition: all 0.5s linear;
}

.iconBContainer:hover .iconBColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}

.iconBContainer:hover .iconB{
    opacity: 0;
    transform: translateY(-0.8rem);
}

/* Icon B Hover Effect Ends ---------------------------------------*/

/* Icon i2 Hover Effect Start ---------------------------------------*/
.iconI2Color{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconI2{
    opacity: 1;
    transition: all 0.5s linear;
}
.iconI2Container:hover .iconI2Color{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconI2Container:hover .iconI2{
    opacity: 0;
    transform: translateY(-0.8rem);
}
/* Icon i2 Hover Effect Ends ---------------------------------------*/

/* Icon N Hover Effect Starts ---------------------------------------*/
.iconNColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconN{
    opacity: 1;
    transition: all 0.5s linear;
}
.iconNContainer:hover .iconNColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconNContainer:hover .iconN{
    opacity: 0;
    transform: translateY(-0.8rem);
}
/* Icon N Hover Effect Ends ---------------------------------------*/

/* Icon G Hover Effect Starts ---------------------------------------*/
.iconGColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconG{
    opacity: 1;
    transition: all 0.5s linear;
}
.iconGContainer:hover .iconGColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconGContainer:hover .iconG{
    opacity: 0;
    transform: translateY(-0.8rem);
}
/* Icon G Hover Effect Ends ---------------------------------------*/

/* Icon Dot Hover Effect Starts ---------------------------------------*/
.iconDotColor{
    z-index: -10;
    opacity: 0;
    transition: all 0.5s linear;
}
.iconDot{
    opacity: 1;
    transition: all 0.5s linear;
}
.iconDotContainer:hover .iconDotColor{
    z-index: 10;
    opacity: 1;
    transform: translateY(-0.8rem);
}
.iconDotContainer:hover .iconDot{
    opacity: 0;
    transform: translateY(-0.8rem);
}
/* Icon Dot Hover Effect Ends ---------------------------------------*/


.weBuild , .weVibe , .weCreate{
    opacity: 0;
    transition: all 0.5s linear;
    position: absolute;
}
.weBuild{
    top: -5rem;
}
.weCreate{
    top: -1rem;
}
.weVibe{
    top: 1rem;
}


.iconVIContainer:hover .weBuild{
    top: -6.8rem;
    opacity: 1;
}

.iconBIContainer:hover .weCreate{
    top: -3rem;
    opacity: 1;
}

.iconNGContainer:hover .weVibe{
    top: -1rem;
    opacity: 1;
}

@media screen and (max-width : 1100px) {
    .iconVIContainer:hover .weBuild{
        top: -5rem;
        opacity: 1;
    }
    .iconBIContainer:hover .weCreate{
        top: -2.2rem;
        opacity: 1;
    }
    .iconNGContainer:hover .weVibe{
        top: -0.5rem;
        opacity: 1;
    }
}

.vibingPass{
    box-shadow: none;
    transition: all 0.5s linear;
    cursor: pointer;
    animation: glowGolden 3s ease-in-out infinite;
}
.vibingPass:hover{
    filter: drop-shadow(0 0 0.75rem #FFEC04);
    transform: translateX(0.5rem);
    transform: scale(1.1);
}

@keyframes glowGolden {
    0% {
        filter: drop-shadow(0 0 0rem transparent);
    }
    50% {
        filter: drop-shadow(0 0 1rem #FFEC04)  drop-shadow(0 0 0.50rem #ffea04a7);
    }
    100% {
        filter: drop-shadow(0 0 0rem transparent);
    }
    
}

 