@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-container {
    @apply lg:w-[45%] md:w-[60%] sm:w-[80%] w-[90%] m-auto text-justify text-[1.1rem] leading-[1.8] font-nunito font-[500];
  }
  .primaryButton {
    @apply bg-primary py-2 px-12 shadow-md cursor-pointer shadow-primary rounded-full border-0 text-white font-nunito font-bold text-[1.2rem];
  }
  .executiveDes {
    @apply lg:w-[70%] w-[85%] pt-16 text-justify font-nunito text-[1.2rem] font-[500] leading-[1.7]
  }
  .navLinkStyle {
    @apply text-lg font-semibold text-[1.2rem] no-underline font-nunito mx-4 relative
  }
  .subNavLinkStyle {
    @apply block no-underline text-primary font-nunito text-[1rem]
  }
  .languageButton {
    @apply bg-transparent border-none font-nunito font-semibold text-[1.2rem] cursor-pointer
  }
  .mobileLanguageButton {
    @apply border-0 rounded-xl font-nunito font-[500] text-[1.5rem] bg-transparent p-2
  }
  .pageDes {
    @apply lg:w-[40%] md:w-[80%] w-[90%] md:text-center text-justify m-auto font-nunito font-[500] leading-[1.7] py-8
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.nav-link:hover:before {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  transition: 0.5s;
}

.nav-link-active:before {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  transition: 0.5s;
}

@media screen and (max-width : 1000px) {
  .nav-link-active:before{
    background-color: white;
  }
}

 .ourGamesLinkContainer{
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
  pointer-events: all;
}